import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="page-footer">
      <p>
        Copyright &copy; {new Date().getFullYear()} Berlin – ideetipp.de – All
        Rights Reserved <br /> <Link to="/datenschutz">Datenschutz</Link> –{" "}
        <Link to="/impressum">Impressum</Link>
        <a
                href="https://www.facebook.com/ideetipp"
                target="_blank"
                rel="noreferrer noopener"
              >
                Facebook
              </a>
      </p>
    </footer>
  )
}

export default Footer
